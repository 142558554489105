import Vue from 'vue'
import {
    Button,
    Carousel,
    CarouselItem,
    Form,
    FormItem,
    Input,
    Radio,
    RadioGroup,
    Steps,
    Step,
} from 'element-ui'
Vue.use(Button)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Steps)
Vue.use(Step)