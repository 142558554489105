<template>
  <!--导航-->
  <div class="nav" style="position: sticky; top: 0; z-index: 100;">
    <div class="nav_big">
      <router-link to="/">
        <div class="nav-logo">
          <img class="logo" v-if="companyInfo.logo" :src="companyInfo.logo"><span class="title">{{companyInfo.title}}</span>
        </div>
      </router-link>
      <div class="mainNav">
        <ul>
          <li v-for="(nav,i) of navigator" :key="i">
            <a :target="nav.target?nav.target:'_self'" :href="nav.href">{{nav.name}}</a>
            <!-- <router-link v-else :to="nav.href">{{nav.name}}</router-link> -->
            <ul>
              <li v-for="(children,j) of nav.children" :key="j">
                <router-link to="children.href">{{children.name}}</router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!--导航 end-->
</template>
<script>

import companyInfo from '../config.js'
export default {
  data(){
    return {
      companyInfo,
      navigator: [
        {
          name: '首页',
          href: 'home.html#/',
          children: [],
        },
        {
          name: '水司介绍',
          href: 'home.html#/introduce',
          children: [],
        },
        {
          name: '企业文化',
          href: 'home.html#/company_dynamics/0',
          children: [],
        },
        {
          name: '水司公告',
          href: 'home.html#/announcement',
          children: [],
        },
        {
          name: '优化营商环境',
          href: 'home.html#/business_environment',
          children: [],
        },
        {
          name: '党建工作',
          href: 'home.html#/company_dynamics/4',
          children: [],
        },
        {
          name: '纪检监察',
          href: 'home.html#/company_dynamics/5',
          children: [],
        },
        {
          name: '网上营业厅',
          href: 'business.html#/home_business',
          target: '_blank',
          children: [],
        }
        ]
    }
  },
  created(){
    console.log(this.logo)
  }
}
</script>