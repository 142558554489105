<template>
  <div id="app_WaterDepartmentPortal">
    <page-head/>
    <router-view></router-view>
    <page-foot :content="companyInfo" />
  </div>
</template>
<script>
import pageHead from './components/pageHead'
import pageFoot from './components/pageFoot'
import VueRouter from 'vue-router'
import Vue from 'vue'
Vue.use(VueRouter)
import routes from './routes'
import BaiduMap from 'vue-baidu-map'
import companyInfo from './config.js'
const router = new VueRouter({
  routes, // (缩写) 相当于 routes: routes
})
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'm3g9GSIBVWFfrlWoDQBgRUTU7veiV47S'
})
export default {
  name: 'app_WaterDepartmentPortal',
  router,
  data() {
    return {
      companyInfo,
      logo: companyInfo.logo,
      
    }
  },

  components: {
    pageHead,
    pageFoot,
  },
  mounted() {
//     console.log(companyInfo)
//     let res ={
//       name:'鲍上',
//       phone:17766269590
//     }
// let fff=JSON.stringify(res)
//     console.log(5555,fff)
//     localStorage.setItem("CURRENT_MEMBER",fff)
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
