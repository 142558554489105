export default {
    title:'衡东县自来水有限公司',//水司名称
    desc:'',//公司简介
    logo:'img/hengdong/logo.png',//水司logo
    copytRight:'衡东县自来水公司版权所有',//版权信息
    tel:'0734-5222221',//联系方式
    Email:'',//邮箱
    address:'衡阳市衡东县兴衡东路92号',//地址
    ICP:'湘ICP备：18003083号',
    recordNumber:'公安备案号：43042402000032',//公安备案号
    links:'',//友情链接
    weChatPublicAccount:'img/hengdong/wechat.jpg',//微信公众号
    weChatMiniProgram:'',//微信小程序
    weibo:'',//微博
    tikTok:'',//抖音
}