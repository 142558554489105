import Vue from "vue";
import Vuex from "vuex";
import storage from "../storage";
// import http from "../http";
Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        shelf: storage.getShelf(),
        member: storage.getMember(),
    },
    getters: {
        size: (state) => state.shelf.length,
    },
    mutations: {
        setMember(state, value) {
            state.member = value;
            storage.saveMember(value);
        },
        addShelf(state, books) {
            state.shelf.push(books);
            storage.saveShelf(state.shelf);
        },
        updateShelf(state, list) {
            console.log(list);
            state.shelf = list;
            storage.saveShelf(list);
        },
    },
});

export default store;