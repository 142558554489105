import Vue from 'vue'
import App from '../../app_home.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import '../../assets/js/element-ui'
import store from '../../vuex/index'
Vue.config.productionTip = false
Vue.use(VueAxios, axios)
new Vue({
  store,
  render: h => h(App),
}).$mount('#app1')