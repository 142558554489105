<template>
  <div>
    <div
      class="imgplayer2"
      style="background: url(img/tonglu.jpg) no-repeat center"
    ></div>
    <div class="c_big">
      <div class="c_left">
        <div class="l_top">水司介绍</div>
        <ul>
          <a href="javascript:0">
            <li
              :class="{ l_hot: current === '水司简介' }"
              @click="setCurrent('水司简介')"
            >
              水司简介
            </li>
          </a>
          <a href="javascript:0">
            <li
              :class="{ l_hot: current === '领导班子' }"
              @click="setCurrent('领导班子')"
            >
              领导班子
            </li>
          </a>
          <a href="javascript:0">
            <li
              :class="{ l_hot: current === '荣誉录' }"
              @click="setCurrent('荣誉录')"
            >
              荣誉录
            </li>
          </a>
          <a href="javascript:0">
            <li
              :class="{ l_hot: current === '大事记' }"
              @click="setCurrent('大事记')"
            >
              大事记
            </li>
          </a>
        </ul>
      </div>
      <div class="c_right">
        <div class="r_top">
          <span id="r_bt">水司简介</span>
          <span id="map"
            >当前位置：水司介绍 &gt;
            <span>{{ activeTitle }}</span>
          </span>
        </div>
        <div class="r_txt" v-show="current === '水司简介'">
          <p style="text-indent: 2rem; line-height: 32px">
            衡东县自来水有限公司始建于1970年，前称为“衡东县供水站”，
            1988年更名为“衡东县自来水公司”，2020年11月完成转企改制，更名为“衡东县自来水有限公司”。公司坐落于衡东县洣水镇衡东大道110号，原隶属于衡东县住房和城乡建设局，2017年1月起应改革需要，划归县水务投资有限公司管理。现有干部职工103人，退休干部职工63人，内设办公室、政工股、财务股、生产技术股、维修队、营业所、纯净水厂、水质监测中心、水厂、安装队、监察队、信息中心共十二个部门，已逐步成长为一家集自来水供应、管道设计安装、水质检测于一体的企业。
          </p>
          <p style="text-indent: 2rem; line-height: 32px">
            公司担负着衡东县洣水镇、吴集镇、河西开发区等区域内的居民和企事业单位生产、生活用水供应，服务人口约11万人。历年来公司严格执行三级水质检测制度，供水水质合格率达99%，我司所属清泉水质检测中心获得湖南省质量技术监督局检验检测资质认定。公司秉承“安全优质供水，用心服务社会”理念，推行精细化管理，打造智慧水务平台，深化优质服务，先后荣获湖南省集中式供水A类单位，湖南省县镇供水先进单位、湖南省巾帼文明岗、衡阳市三星级文明单位、衡阳市工人先锋号等多项殊荣。
          </p>
        </div>
        <div class="r_txt" v-show="current === '领导班子'">
          <table
            class="honor-table"
            border="1"
            style="border-collapse: collapse"
          >
            <thead
              style="height: 36px; text-align: center; background-color: #eee"
            >
              <tr>
                <td>姓名</td>
                <td>职务</td>
                <td>负责内容</td>
              </tr>
            </thead>
            <tbody v-if="leader.length">
              <tr
                style="line-height: 32px"
                v-for="(item, index) in leader"
                :key="index"
              >
                <td style="width: 86px">{{ item.name }}</td>
                <td>{{ item.position }}</td>
                <td>{{ item.desc }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="3">
                  <el-empty description="暂无数据"></el-empty>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="r_txt" v-show="current === '荣誉录'">
          <table
            class="honor-table"
            border="1"
            style="border-collapse: collapse"
          >
            <thead
              style="height: 36px; text-align: center; background-color: #eee"
            >
              <tr>
                <td>年份</td>
                <td>颁发单位</td>
                <td>荣誉称号</td>
              </tr>
            </thead>
            <tbody v-if="honor.length">
              <tr
                style="line-height: 32px"
                v-for="(item, index) in honor"
                :key="index"
              >
                <td>{{ item.year }}</td>
                <td>{{ item.awardingUnit }}</td>
                <td>{{ item.honor }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="3">
                  <el-empty description="暂无数据"></el-empty>
                </td>
              </tr>
            </tbody>
            
          </table>
          <div class="img_zongti" style="black;text-align: center;">
             <ul>
                <li>
                  <img class="box231 one" src="img/hengdong/honor11.png" alt="">
                </li>
                <li>
                  <img class="box231 two" src="img/hengdong/honor22.png" alt="">
                </li>
                <li>
                  <img class="box231 three" src="img/hengdong/honor33.png" alt="">
                </li>
                <li>
                  <img class="box231 fore" src="img/hengdong/honor44.png" alt="">
                </li>
                <li>
                  <img class="box231 five" src="img/hengdong/honer5.jpg" alt="">
                </li>
                <li>
                  <img class="box231 five" src="img/hengdong/honor6.jpg" alt="">
                </li>
            </ul>
            </div>
        </div>

        <div class="r_txt" v-show="current === '大事记'">
          <p style="line-height: 32px; text-indent: 2rem">
            衡东县自来水公司于1970年成立，前称“衡东县供水站”，在编人员7人，隶属衡东县县委办，当年开始筹建河水泵房和水厂，设计供水规模为2000吨/日。1972年，正式向居民供水，用水户仅限县委及周边少量居民，处理工艺为简易沉淀。
          </p>
          <ul>
            <li>1975年，衡东县供水站划归衡东县工业局管理。</li>
            <li>
              1983年，兴建老井泵房，厂址老大桥北头上游60米处，源水为洁净的井水，实行井水、河水同时供水。供水主管道开始延伸，扩大了用水户。
            </li>
            <li>1984年，衡东县供水站划归衡东县建委管理。</li>
            <li>1988年，衡东县供水站更名为衡东县自来水公司。</li>
            <li>
              1989年，随着用水户的增加，老井泵房的上水量满足不了需求，于老大桥北头下游100米处新建第二井水泵房（即新井泵房），由于井下处理效果差，于1991年废弃。随着城市规模的不断扩大，用水人口的不断增加，老井泵房也于20
            </li>
            <li>
              1996年，公司投入资金600余万元对水厂进行全面改造，对河水泵房进行了改造，兴建规模2.5万吨/日水净化系统：格栅反应平流沉淀池、反粒度快滤池、清水池、加压泵房、化验楼及制水车间等。
            </li>
            <li>
              2000年，为适应市民对饮用水的需求，在自来水公司院内成立兴衡纯净水厂。
            </li>
            <li>
              2004年，投入400余万元完成5万吨/日的河水泵房改造，新铺DN600上水主管。并拉通从水厂至草席厂往北沿兴衡大道（武家山）至金花金玉化工高压供水专线。
            </li>
            <li>
              2010年，投入500余万元完成2.5万吨/日水厂扩建工程：兴建格栅反应斜板沉淀池、普通快滤池、清水池等。
            </li>
            <li>
              2011年，投入60余万元完成改造老井泵房园林式改造，新建了兴衡纯净水厂房。
            </li>
            <li>
              2012年—2013年，共投入300余万元完成水厂供水提质改造，水厂从此展现崭新面貌，在县级水司处于领先水平。
            </li>
            <li>1、完成加压泵房全自动变频加压改造；</li>
            <li>
              2、完成化验楼改造，添置检测设备，实现化验检测42项以上（以前只能检测12项）；
            </li>
            <li>3、完成二氧化氯消毒及全自动投加系统改造。</li>
            <li>
              2014年，投入200余万元完成河水泵房园林式改造，拓展占地面积1300多平方米，新建库房300多平方米，绿化改造1000多平方米。从2010年至2014年，共投入900余万元，完成DN100以上供水主管改造及新铺2万多米，维修停水现象日渐减少，确保了供水安全。至目前为止，公司固定资产已逾2000万元。
            </li>
            <li>
              2017年11月17日公司顺利通过湖南省集中式供水“A”类卫生单位考评验收。
            </li>
          </ul>
          <p style="line-height: 32px; text-indent: 2rem">
            公司原隶属于衡东县住房和城乡建设局下属二级副科级单位，2017年1月起应改革需要，划归县水务投资有限公司管理。公司是公益社会服务型自收自支性事业单位，实行事业单位企业化管理。公司业务范围涵盖自来水、桶装纯净水的生产和销售、水表校验；承接各种给水工程施工及管道安装维修、水质检测等。公司现有干部职工145人，内设办公室、政工股、财务股、生产技术股、维修队、营业所、纯净水厂、水质监测中心、水厂、安装队、监察队共十一个二级机构，公司拥有固定资产原值2890万元，铺设直径100毫米以上供水管道85千米，设计日供水能力5万吨，担负着衡东县城区、吴集镇、洣水镇、河西开发区等区域内的居民和企事业单位生产、生活用水的供应任务。2017年公司收入总产值2643万元，缴纳税金112万元。年供水量768万吨；水回收量553万立方米；水回收率72%.（不含公益用水）。
          </p>
          <p style="line-height: 32px; text-indent: 2rem">
            特别值得一提的是，2018年6月16日开始的城关供水用户供水秩序整治工作开展以来，整治成果斐然：更换水表153块；清洗水表500多块；
            更换表前阀599个；维修漏水点70多处；催费停水4000多人次；查处私搭乱接违章用水未开户89多户
            ，补交开户材料工费27.4万元
            ，补交水费18万余元；规范有水表无交费户头41户，补交水费6.15万元；对于存在抄表难和地埋水表池污染物多的地方，整治组及时提出整改意见并及时进行整改。
            近年来，公司在县四大家的正确领导下，全体员工，不忘初心，牢记使命，坚持以优质供水，诚信服务为宗旨，真抓实干，务实拼博，坚持用户至上、文明服务，供水工作连年得到了上级领导和社会各界的充分肯定和好评，获得了全省饮用水卫生管理先进单位、全市一星级、二星级、三星级文明单位、全县先进基层党组织等荣誉称号。
          </p>
        </div>
      </div>
      <div style="clear: both"></div>
    </div>
  </div>
</template>
<style scope>
.honor-table {
  width: 100%;
}
.honor-table td {
  padding: 0 10px;
}
</style>
<script>
import http from "../http/index";
export default {
  data() {
    return {
      leader: [],
      current: "水司简介",
      activeTitle: "水司简介",
      honor: [],
    };
  },
  methods: {
    async setCurrent(val) {
      this.current = val;
      this.activeTitle = val;
      if (val === "荣誉录") {
        this.honor = await http.getHonor();
        // axios.get('files/hengdong/honor.json')
        // .then((result) => {
        //   this.honor=result
        //   console.log(result)
        // }).catch((err) => {
        //    console.log('报错',err)
        // });
      }
      if (val === "领导班子") {
        this.leader = await http.getLeaders();
        // axios.get('files/hengdong/leader.json')
        // .then((result) => {
        //   this.leader=result
        //   console.log(result)
        // }).catch((err) => {
        //    console.log('报错',err)
        // });
      }
    },
  },
};
</script>

<style>
.r_txt .r_txt_title {
  color: #064a93;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 5px;
}
/*.r_txt_t img:first-child{*/
/*  width: 790px;*/
/*}*/
._135editor img:not(.loadingclass) {
  width: 790px;
}
.c_big .c_left {
  min-height: 0px;
}
.img_zongti{
  display: inline-block;
  padding: 7px 2px 0 2px;
}
.sapn_zaopian{
  font-size: 24px;
  text-align: center;
}
.img_zongti ul li .one:active{
  transform: translate(171px,-300px) scale(4.5);
  border-radius: 2%;
  transition: all 0.5s;
}
.img_zongti ul li .two:active{
  transform: translate(-57px,-300px) scale(4.5);
  border-radius: 2%;
  transition: all 0.5s;
}
.img_zongti ul li .three:active{
  transform: translate(-215px,-300px) scale(4.5);
  border-radius: 2%;
  transition: all 0.5s;
}
.img_zongti ul li .fore:active{
  transform: translate(-428px,-300px) scale(4.5);
  border-radius: 2%;
  transition: all 0.5s;
}
.img_zongti ul li .five:active{
  transform: translate(-120px,-300px) scale(4.5);
  border-radius: 2%;
  transition: all 0.5s;
}
.box231{
  width: 188px;
  height: 160px;
  display: flex;
  text-align: center;
}
.img_zongti ul>li{
  width: 187px;
  display: inline-block;
  margin: 0px 3px;
  background-color: white;
}
.img_zongti ul{
  box-sizing: border-box;
}
</style>