<template>
  <div class="imgplayer">
    <div class="banner">
      <div class="focus">
        <el-carousel ref="carousel" indicator-position="none">
          <el-carousel-item v-for="(banner,i) of banners" :key="i">
            <img :src="banner.imgURL" alt="点滴间 桐水情">
          </el-carousel-item>
        </el-carousel>
        <!-- <ul id="big_list" style="margin-left: -2280px;">
          <li v-for="(banner,i) of banners" :key="i">
            <div>
              <a :href="banner.href" :target="banner.target?banner.target:'_blank'">
                <img :src="banner.imgURL" alt="点滴间 桐水情"></a>
            </div>
          </li>
        </ul> -->
      </div>
      <div class="small_show">
        <ul>
          <li v-for="(banner,i) of banners" :key="i" :class="{visited:visited===i}" @click="setActiveItem(i)">
            <div class="banner-dot">{{i+1}}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    banners: Array,
    visited: Number,
  },
  methods: {
    setActiveItem(i) {
      this.$refs.carousel.setActiveItem(i)
    },
  },
}
</script>