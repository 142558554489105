import axios from "axios";

// axios.defaults.baseURL = "/";
// axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';


// 添加响应拦截器
axios.interceptors.response.use(
    function (response) {
        // 对响应数据做点什么
        console.log('-请求成功-',response)
        const {
            code,
            data,
            msg
        } = response.data;
        if(response.status===200&&!code){
            return response.data
        }
        if (code === 200) {
            return data;
        }
        return Promise.reject(msg);
    },
    function (error) {
        // 对响应错误做点什么
        console.log('-请求错误-',error);
        return Promise.reject(error);
    }
);
export default {
    // 获取水务新闻数据
    getNewsMsg() {
        return axios.get("/weipay/information/getNewsSwxw?offset=1&counts=5")
    },
    // 获取停水公告
    cutWater() {
        return axios.get("/weipay/information/getCutWaterNotice.do")
    },
    //获取企业简介
    getEssayList(){
        return axios.post("/weipay/information/getEssayList.do?type=8")
    },
    // 获取营业网点
    getBusiness(){
        return axios.post('/weipay/information/getBusiness.do')
    },
    // 获取水质公告信息
    getWater() {
        return axios.post('/weipay/SZGS/getValue?shuiwu=龙华水务')
    },
    // 获取常见问题
    getQuestion(){
        return axios.post("/weipay/information/getEssayList.do?type=2")
    },
    // 获取用水政策
    getWaterpolicy(){
        return axios.post("/weipay/information/getEssayList.do?type=1")
    },
    // 获取水价信息
    getWaterPrice(){
        return axios.post("/weipay/information/getEssayList.do?type=4")
    },
    // 获取业务指南信息
    getBusinessGuide(){
        return axios.post("/weipay/information/getEssayList.do?type=7")
    },
    goPaymoney(usercode){
        return axios.get(`/weipay/rechargelist/monthlybillList_longhua?userCode=${usercode}`)
    },
    getNotice(){
        return axios.post("/weipay/LBGG/selectLBGG")
    },
    getExcellent(){
      return axios.post("/weipay/information/getExcellentDrink.do")
    },
    getpromise(){
        return axios.post("/weipay/information/getEssayList.do?type=3")
    },
    getlist(){
        return axios.get('json/left.json')
    },
    //衡东获取水质公示(本地)
    getWaterQualityReport(){
        return axios.get('json/hengdong/waterQualityReport.json')
    },
    //衡东县获取荣誉录(本地)
    getHonor(){
        return axios.get('files/hengdong/honor.json')
    },
    //衡东县获取领导班子
    getLeaders(){
        return axios.get('files/hengdong/leader.json')
    },
    //衡东县获取优化营商环境
    getOptimizeTheBusinessEnv(){
        return axios.get('json/hengdong/optimizeTheBusinessEnv.json')
    },
    // 水务新闻
    getNews(){
        return axios.get('json/hengdong/news.json')
    },
    // 党建工作
    getdangjianList(){
        return axios.get('json/hengdong/dangjiangongzuo.json')
    },
    //衡东县获去群团工作
    getGroupWork(){
        return axios.get('json/hengdong/groupWork.json')
    },
    //衡东县获取纪检监察
    getInspection(){
        return axios.get('json/hengdong/inspection.json')
    },
    //获取节水宣传
    getWaterSaving(){
        return axios.get('json/hengdong/waterSaving.json')
    },
    getgetAnnouncement(){
        return axios.get('json/hengdong/announcement.json')
    },
    // 获取政策
    getgetZhengce(){
        return axios.get('json/hengdong/zhengce.json')
    },
    // 获取用水便利度
    getgetYongshuibianlidu(){
        return axios.get('json/hengdong/yongshuibianlidu.json')
    },
    //获取相关报道
    getgetRelatedReports(){
        return axios.get('json/hengdong/relatedReports.json')
    },
    // 获取用水成本
    getgetYongshuichengben(){
        return axios.get('json/hengdong/yongshuichengben.json')
    }

}
