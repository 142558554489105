<template>
  <div>
    <div class="foot">
      <div class="f_big">
        <div class="f_a">
          <router-link :to="{path:'/detail',query:{nc:101036001}}" v-for="(link,i) of content.links" :key="i">{{link.text}}</router-link>
        </div>
        <!-- <img src="img/logo2.jpg"> -->
<!--        <img src="img/logo2.jpg">-->
        <!-- <img src="img/hendong.jpg"> -->
      </div>
      <div class="f_bottom" style="margin-top: -50px;">
        <div v-if="content.tel">服务热线：{{content.tel}}</div>
        <div v-if="content.Email">E-mail: {{content.Email}}</div>
        <div v-if="content.copytRight">Copyright © {{content.copytRight}}</div>
        <!-- <div v-if="companyInfo.recordNumber">公安备案号:{{companyInfo.recordNumber}}</div> -->
        <div v-if="content.ICP"><a href="https://beian.miit.gov.cn/" target="_blank">{{content.ICP}}</a></div>
        <div v-if="content.recordNumber" style="display:flex;align-items:center;">
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030402000868" ref="r_a">
            <img src="img/ba.png" ref="ba">{{content.recordNumber}}
          </a>
        </div>
        <!-- <div>
          <dl>
            <dt></dt>
            <dt></dt>
            <dd></dd>
            <dd></dd>
          </dl>

          <div class="r_big">
            <img src="img/wb.jpg">
            <img width="40px" src="img/sznet110gangting.gif">
            <img src="img/biaoshi.gif">
            <span style="vertical-align: top">
            </span>
            <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030402000868" ref="r_a">
              <img src="img/ba.png" ref="ba">{{content.recordNumber}}
            </a>
          </div>
        </div> -->
      </div>
    </div>

    <!--底部 end-->
    <!-- 右侧悬浮框1 -->
    <!-- <div ref="tel_right1" style="position:fixed;right:10px;top:66%;z-index:10001">
      <a href="https://www.gdzwfw.gov.cn/portal/branch-hall?orgCode=192175541" title="广东政务网" target="_blank">
        <img :src="content.logo_foot" width="180" height="100">
      </a>
    </div> -->
    <!-- 右侧悬浮框1 end -->
    <!-- 右侧悬浮框2 -->
    <!-- <div ref="tel_right" style="position:fixed;right:10px;top:80%;z-index:10001">
      <a ref="closeA" style="width: 18px;height: 18px;position:absolute;top:0px;right:4px;text-decoration: none;color: #333;font-size: 12px;background: url(img/close.png) no-repeat center center;"
        title=" 关闭" href="javascript:void(0);" onclick="ShowH()"></a>
      <a href="tel:0755-82132377" title="清欠投诉电话" target="_blank">
        <img src="img/tel.jpg" width="150" height="100">
      </a>
    </div> -->
    <!-- 右侧悬浮框2 end -->
  </div>
</template>
<script>
export default {
  props: {
    content: Object,
    // links: Array, //友情链接
    // logo: String, //底部logo
    // copytRight: String, //版权信息
    // serviceHotline: String, //服务热线
    // Email: String, //电子邮箱
    // recordNumber: String, //公网安全备案号
    // ICP: String, //ICP备案号
  },
  mounted(){
    // console.log(this.content);
  }
};
</script>
<style>
.foot .f_big img {
   width: 60px !important;
  height: 60px;
  float: right;
}
</style>