const CURRENT_MEMBER = "CURRENT_MEMBER";
const SHELF_LIST = "SHELF_LIST";
export default {
    // 用户
    saveMember(member) {
        localStorage.setItem(CURRENT_MEMBER, JSON.stringify(member));
    },
    getMember() {
        return JSON.parse(localStorage.getItem(CURRENT_MEMBER));
    },
    removeMember() {
        localStorage.removeItem(CURRENT_MEMBER);
    },
    saveShelf(shelf) {
        localStorage.setItem(SHELF_LIST, JSON.stringify(shelf));
    },
    getShelf() {
        return JSON.parse(localStorage.getItem(SHELF_LIST)) || [];
    },
};