<template>
  <div>
    <div class="imgplayer2" style="background: url(img/gonggao.jpg) no-repeat center;"></div>
    <div class="c_big">
      <div class="c_left">
        <div class="l_top">优化营商环境</div>
        <ul>
          <a href="javascript:void(0)">
            <li :class="{l_hot:current===0}" @click="current=0,currentPage=1">政策</li>
          </a>
          <a href="javascript:void(0)">
            <li :class="{l_hot:current===1}" @click="current=1,currentPage=1">获得用水便利度</li>
          </a>
          <a href="javascript:void(0)">
            <li :class="{l_hot:current===2}" @click="current=2,currentPage=1">获得用水成本</li>
          </a>
          <a href="javascript:void(0)">
            <li :class="{l_hot:current===3}" @click="current=3,currentPage=1">我为群众办实事</li>
          </a>
          <a href="javascript:void(0)">
            <li :class="{l_hot:current===4}" @click="current=4,currentPage=1">节水宣传</li>
          </a>
          <a href="javascript:void(0)">
            <li :class="{l_hot:current===5}" @click="current=5,currentPage=1">相关报道</li>
          </a>
          <a href="javascript:void(0)">
            <li :class="{l_hot:current===6}" @click="current=6,currentPage=1">领导信箱</li>
          </a>
          <!-- <a href="javascript:void(0)">
            <li :class="{l_hot:current===7}" @click="current=7,currentPage=1">未定义</li>
          </a> -->
        </ul>
      </div>


      <div class="c_right" v-show="current===0">

        <div class="r_top"><span id="r_bt">政策</span><span id="map">当前位置：优化营商环境 &gt; 政策</span></div>
        <div v-if="waterAnnouncement1.length||zhengce.length">
          <div class="r_txt">
          <ul>
            <!-- <li v-for="(item,index) in waterAnnouncement1" :key="index">
              <span id="news_nav" @click="openUrl(item)">•&nbsp; {{ item.title }}</span>
              <span id="time">{{ item.createtime |Date}}</span>
            </li> -->
            <li v-for="(item) in zhengce" :key="item.id" @click="showzhengce(item)">
                <span id="news_nav">•&nbsp; {{ item.title }}</span>
                <span id="time">{{ item.createTime}}</span>
              </li>
          </ul>
        </div>

        <!--          分页功能-->
        <div class="flex" style="margin-bottom: 10px;display: flex;">
          <el-button size="mini" @click="gotoFirst()">首页</el-button>
          <el-pagination
              background
              :page-size="15"
              :current-page="currentPage"
              layout="prev, pager, next"
              :total="waterAnnouncement.length"
              @current-change="change"
              prev-text="上一页"
              next-text="下一页"
          >
          </el-pagination>
          <el-button size="mini" @click="gotoEnd(waterAnnouncement)">末页</el-button>
        </div>
        </div>
         <el-empty v-else></el-empty>
      </div>
      <div class="c_right" v-show="current===1">

        <div class="r_top"><span id="r_bt">获得用水便利度</span><span id="map">当前位置：优化营商环境 &gt; 获得用水便利度</span></div>
        <div v-if="waterAnnouncement1.length||yongshuibianlidu.length">
          <div class="r_txt">
          <ul style="height:370px;overflow-y:auto;">
            <!-- <li v-for="(item,index) in waterAnnouncement1" :key="index">
              <span id="news_nav" @click="openUrl(item)">•&nbsp; {{ item.title }}</span>
              <span id="time">{{ item.createtime |Date}}</span>
            </li> -->
            <li v-for="(item) in yongshuibianlidu" :key="item.id" @click="showyongshuibianlidu(item)">
                <span id="news_nav">•&nbsp; {{ item.title }}</span>
                <span id="time">{{ item.createTime}}</span>
              </li>
          </ul>
        </div>

        <!--          分页功能-->
        <div class="flex" style="margin-bottom: 10px;display: flex;">
          <el-button size="mini" @click="gotoFirst()">首页</el-button>
          <el-pagination
              background
              :page-size="15"
              :current-page="currentPage"
              layout="prev, pager, next"
              :total="waterAnnouncement.length"
              @current-change="change"
              prev-text="上一页"
              next-text="下一页"
          >
          </el-pagination>
          <el-button size="mini" @click="gotoEnd(waterAnnouncement)">末页</el-button>
        </div>
        </div>
         <el-empty v-else></el-empty>
      </div>

      <div class="c_right" v-show="current===2">
        <div class="r_top"><span id="r_bt">获得用水成本</span><span id="map">当前位置：优化营商环境 &gt; 获得用水成本</span></div>
        <div v-if="waterExcellent1.length||yongshuichengben.length">
          <div class="r_txt">
          <ul  style="height:370px;overflow-y:auto;">
            <!-- <li v-for="(item,index) in waterAnnouncement1" :key="index">
              <span id="news_nav" @click="openUrl(item)">•&nbsp; {{ item.title }}</span>
              <span id="time">{{ item.createtime |Date}}</span>
            </li> -->
            <li v-for="(item) in yongshuichengben" :key="item.id" @click="showyongshuichengben(item)">
                <span id="news_nav">•&nbsp; {{ item.title }}</span>
                <span id="time">{{ item.createTime}}</span>
              </li>
          </ul>
        </div>
         <!-- <div class="r_txt"> -->
        <!--          分页功能-->
        <div class="flex" style="margin-bottom: 10px;display: flex;">
          <el-button size="mini" @click="gotoFirst()">首页</el-button>
          <el-pagination
              background
              :page-size="15"
              :current-page="currentPage"
              layout="prev, pager, next"
              :total="waterExcellent.length"
              @current-change="change"
              prev-text="上一页"
              next-text="下一页"
          >
          </el-pagination>
          <el-button size="mini" @click="gotoEnd(waterExcellent)">末页</el-button>
        </div>
        </div>
         <el-empty v-else></el-empty>
        
        
      </div>

      <div class="c_right" v-show="current===3">
        <div class="r_top"><span id="r_bt">我为群众办实事</span><span id="map">当前位置：优化营商环境 &gt; 我为群众办实事</span></div>
        <div v-if="waterAnnouncement1.length||announcement.length">
          <div class="r_txt">
            <ul style="height:370px;overflow-y:auto;">
              <li v-for="(item) in announcement" :key="item.id" @click="showContent(item)">
                <span id="news_nav">•&nbsp; {{ item.title }}</span>
                <span id="time">{{ item.createTime}}</span>
              </li>
              <!-- <li v-for="(item,index) in waterAnnouncement1" :key="index">
                <span id="news_nav" @click="openUrl(item)">•&nbsp; {{ item.title }}</span>
                <span id="time">{{ item.createtime |Date}}</span>
              </li> -->
            </ul>
          </div>

        <!--          分页功能-->
        <div class="flex" style="margin-bottom: 10px;display: flex;">
          <el-button size="mini" @click="gotoFirst()">首页</el-button>
          <el-pagination
              background
              :page-size="15"
              :current-page="currentPage"
              layout="prev, pager, next"
              :total="waterAnnouncement.length"
              @current-change="change"
              prev-text="上一页"
              next-text="下一页"
          >
          </el-pagination>
          <el-button size="mini" @click="gotoEnd(waterAnnouncement)">末页</el-button>
        </div>
        </div>
         <el-empty v-else></el-empty>
      </div>
      <!-- <div class="c_right" v-show="current===4">
        <div class="r_top"><span id="r_bt">节水宣传</span><span id="map">当前位置：优化营商环境 &gt; 节水宣传</span></div>
        <div class="r_txt">
          <div v-html="content"></div>
          <ul style="height:370px;overflow-y:auto;">
            <li v-for="item in waterSaving" :key="item.id" :style="{fontWeight:selected===item.id?'bold':''}" @click="showContently(item)">
              <a  style="display:flex" href="javascript:void(0)">
                  <span style="flex:1">· {{item.title}} </span>  
                 <span>{{item.createTime}}</span>
              </a>
            </li>
          </ul>
          </div>
        </div> -->
        <div class="c_right" v-show="current===4">
        <div class="r_top"><span id="r_bt">节水宣传</span><span id="map">当前位置：优化营商环境 &gt; 节水宣传</span></div>
        <div v-if="waterExcellent1.length||waterSaving.length">
         <div class="r_txt">
          <ul style="height:370px;overflow-y:auto;">
            <li v-for="(item) in waterSaving" :key="item.id" @click="showContents(item)">
                  
                  <span id="news_nav" style="flex:1">•&nbsp; {{item.title}} </span>  
                 <span id="time">{{item.createTime}}</span>
             
                  
            </li>
          </ul>
          </div>

        <!--          分页功能-->
        <div class="flex" style="margin-bottom: 10px;display: flex;">
          <el-button size="mini" @click="gotoFirst()">首页</el-button>
          <el-pagination
              background
              :page-size="15"
              :current-page="currentPage"
              layout="prev, pager, next"
              :total="waterExcellent.length"
              @current-change="change"
              prev-text="上一页"
              next-text="下一页"
          >
          </el-pagination>
          <el-button size="mini" @click="gotoEnd(waterExcellent)">末页</el-button>
        </div>
        </div>
        
         <el-empty v-else></el-empty>
      </div>
      <div class="c_right" v-show="current===5">
        <div class="r_top"><span id="r_bt">相关报道</span><span id="map">当前位置：优化营商环境 &gt; 相关报道</span></div>
        <div v-if="waterExcellent1.length||relatedReports.length">
         <div class="r_txt">
          <ul style="height:370px;overflow-y:auto;">
            <li v-for="(item) in relatedReports" :key="item.id"  @click="showReports(item)">
                  <span id="news_nav" style="flex:1">•&nbsp; {{item.title}} </span>  
                 <span id="time">{{item.createTime}}</span>
            </li>
          </ul>
          </div>

        <!--          分页功能-->
        <div class="flex" style="margin-bottom: 10px;display: flex;">
          <el-button size="mini" @click="gotoFirst()">首页</el-button>
          <el-pagination
              background
              :page-size="15"
              :current-page="currentPage"
              layout="prev, pager, next"
              :total="waterExcellent.length"
              @current-change="change"
              prev-text="上一页"
              next-text="下一页"
          >
          </el-pagination>
          <el-button size="mini" @click="gotoEnd(waterExcellent)">末页</el-button>
        </div>
        </div>
         <el-empty v-else></el-empty>
      </div>
      <div class="c_right" v-show="current===6">
        <div class="r_top"><span id="r_bt">领导信箱</span><span id="map">当前位置：优化营商环境 &gt; 领导信箱</span></div>
        <div v-if="waterExcellent1.length">
         <div class="r_txt">
          <ul>
            <li style="display:flex;" v-for="(item,index) in waterExcellent1" :key="index">
              <span  style="flex:1">
                 <router-link :to="{ path: '/detail', query: { nc: 101034001, id: item.id } }" target="_blank">
                  <span id="news_nav" @click="openUrl(item)">•&nbsp; {{ item.compoundName }}</span>
                </router-link>
              </span>
             
              <span id="time">{{ item.createTime}}</span>
            </li>
          </ul>
          </div>

        <!--          分页功能-->
        <div class="flex" style="margin-bottom: 10px;display: flex;">
          <el-button size="mini" @click="gotoFirst()">首页</el-button>
          <el-pagination
              background
              :page-size="15"
              :current-page="currentPage"
              layout="prev, pager, next"
              :total="waterExcellent.length"
              @current-change="change"
              prev-text="上一页"
              next-text="下一页"
          >
          </el-pagination>
          <el-button size="mini" @click="gotoEnd(waterExcellent)">末页</el-button>
        </div>
        </div>
         <el-empty v-else></el-empty>
      </div>
      
      <div style="clear: both;"></div>
    </div>
  </div>
</template>

<script>
//引入element组件库
import ElementUI from "element-ui";
// 引入http
import http from "../http/index";
import Vue from "vue";
Vue.use(ElementUI);
export default {
  data() {
    return {
      current: 0,
      currentPage:1,
      waterAnnouncement:[],
      waterExcellent:[],
      waterNews:[],
      cutWaterlist:[],
      announcement:[],
      zhengce:[],
      yongshuichengben:[],
      yongshuibianlidu:[],
      waterSaving:[],
      relatedReports:[],
    }
  },
  filters: {
    // 返回一个日期  年-月-日
    Date(val) {
      return val.slice(0, 10);
    },
  },
  methods: {
    // 相关报道
    showReports(obj){
      if(obj.type==='text'){
          Vue.axios.get(obj.url).then(res=>{
            this.content=res
          })
      }
      if(obj.type==='url'){
        window.open(obj.url)
      }
      this.selected=obj.id;
    },
    async getRelatedReports(){
      this.relatedReports = await http.getgetRelatedReports()
    },
    // 获得用水成本
    showyongshuichengben(obj){
      if(obj.type==='text'){
          Vue.axios.get(obj.url).then(res=>{
            this.content=res
          })
      }
      if(obj.type==='url'){
        window.open(obj.url)
      }
      this.selected=obj.id;
    },
    async getYongshuichengben(){
      this.yongshuichengben = await http.getgetYongshuichengben()
    },
    // 获得用水便利度
    showyongshuibianlidu(obj){
      if(obj.type==='text'){
          Vue.axios.get(obj.url).then(res=>{
            this.content=res
          })
      }
      if(obj.type==='url'){
        window.open(obj.url)
      }
      this.selected=obj.id;
    },
    async getYongshuibianlidu(){
      this.yongshuibianlidu = await http.getgetYongshuibianlidu()
    },
    // 我为群众办实事栏
    showContent(obj){
      if(obj.type==='text'){
          Vue.axios.get(obj.url).then(res=>{
            this.content=res
          })
      }
      if(obj.type==='url'){
        window.open(obj.url)
      }
      this.selected=obj.id;
    },
    async getAnnouncement(){
      this.announcement = await http.getgetAnnouncement()
    },
    // 节水宣传
    showContents(obj){
      if(obj.type==='text'){
          Vue.axios.get(obj.url).then(res=>{
            this.content=res
          })
      }
      if(obj.type==='url'){
        window.open(obj.url)
      }
      this.selected=obj.id;
    },
    async getWaterSaving(){
       this.waterSaving= await http.getWaterSaving();
    },
    // showContently(obj){
    //   if(obj.type==='text'){
    //       Vue.axios.get(obj.url).then(res=>{
    //         this.content=res
    //       })
    //   }
    //   if(obj.type==='url'){
    //     window.open(obj.url)
    //   }
    //   this.selected=obj.id;
    // },
    // async getWaterSaving(){
    //   this.waterSaving= await http.getWaterSaving();
    // },
    // 政策栏
    showzhengce(obj){
      if(obj.type==='text'){
          Vue.axios.get(obj.url).then(res=>{
            this.content=res
          })
      }
      if(obj.type==='url'){
        window.open(obj.url)
      }
      this.selected=obj.id;
    },
    async getZhengce(){
      this.zhengce = await http.getgetZhengce()
    },
    async getNotice() {
      try {
        const res = await http.getNotice();
        // console.log("通知公告", res);
        this.waterAnnouncement = res.sort(this.SortDate("createtime", false))
        // this.SwNews = res.rows.sort(this.SortDate('update_time',false));
      } catch (ex) {
        console.log(ex);
      }
    },

    async getExcellent() {
      try {
        const res = await http.getExcellent();
        // console.log("gongcheng", res);
        this.waterExcellent = res.sort(this.SortDate("createtime", false))
        // console.log('waterExcellent', this.waterExcellent)
        // this.SwNews = res.rows.sort(this.SortDate('update_time',false));
      } catch (ex) {
        console.log(ex);
      }
    },
    // 获取水质公告
    async getWater() {
      try {
        this.waterNews = await http.getWaterQualityReport();
      } catch (ex) {
        console.log(ex);
      }
    },
    // 获取停水公告数据
    async cutWater() {
      try {
        const res = await http.cutWater();
        // console.log("5555666", res);
        this.cutWaterlist = res.sort(this.SortDate('createtime',false));
      } catch (ex) {
        console.log(ex);
      }
    },


    // 按时间进行排序算法
    SortDate(time, type) {
      //createtime
      return function (a, b) {
        let value1 = a[time]
        let value2 = b[time]
        if (type) {
          //升序排序
          return Date.parse(value1) - Date.parse(value2)
        } else {
          //降序
          return Date.parse(value2) - Date.parse(value1)
        }
      }
    },
    //分页切换
    change(val) {
      // console.log(`第${val}页`);
      this.currentPage = val;
    },
    // 回到首页
    gotoFirst() {
      this.currentPage = 1;
    },
    // 去到末页
    gotoEnd(list) {
      this.currentPage = Math.ceil(list.length / 15);
    },
    openUrl(list) {
      if(list.urltype == 2){
        window.open(list.url)
      }
      if(list.urltype == 0){
        this.$alert(list.content, list.title, {
          confirmButtonText: '确定',
          type:'info'
        });
        // alert(list.content)
      }
      if(list.urltype == 4){
        let {href} = this.$router.resolve({path:'/detail',query:{nc:101034004,id:list.pingtaitype}})
        window.open(href,"_blank")
      }
    },
  },
  computed:{
    waterAnnouncement1(){
      return this.waterAnnouncement.slice((this.currentPage - 1) * 15, this.currentPage * 15)
    },
    waterExcellent1(){
      return this.waterExcellent.slice((this.currentPage - 1) * 15, this.currentPage * 15)
    },
    // waterNews1(){
    //   return this.waterNews.slice((this.currentPage - 1) * 15, this.currentPage * 15)
    // },
    cutWaterlist1(){
      return this.cutWaterlist.slice((this.currentPage - 1) * 15, this.currentPage * 15)
    }
  },
  // mounted() {
  //   this.getNotice()
  //   this.getExcellent()
  //   this.getWater()
  //   this.cutWater()
  // }
  mounted() {
    this.getNotice()
    this.getExcellent()
    this.getWater()
    this.cutWater()
    this.getAnnouncement()
    this.getZhengce()
    this.getYongshuibianlidu()
    this.getYongshuichengben()
    // this.getWaterSaving()
    this.getWaterSaving()
    this.getRelatedReports()
  }
}
</script>

<style>
</style>