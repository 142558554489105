<template>
  <div>
    <page-banner v-if="banners.length" :banners="banners" :visited="0" />
    <div class="three">
      <div class="tabs" id="tabs">
        <ul class="horizontal">
          <li
            rel="tab-1"
            :class="{ selectActive: active === 0 }"
            @click="active = 0"
          >
            停水通知
          </li>
          <li
            rel="tab-2"
            :class="{ selectActive: active === 1 }"
            @click="active = 1"
          >
            通知通告
          </li>
          <li
            rel="tab-3"
            :class="{ selectActive: active === 2 }"
            @click="active = 2"
          >
            水质公告
          </li>
          <!--20190701 hide <li rel="tab-4">水情通告</li> -->
        </ul>
        <div rel="tab-1" style="display: block;padding: 10px;" v-if="active === 0">
          <el-empty v-if="cutWaterlist1.length===0" description="暂无数据" :image-size="150"></el-empty>
          <ul v-else>
            <li v-for="(item,index) in cutWaterlist1" :key="index">
              <img src="img/nav.jpg" />
              <span id="time">{{item.createtime | Date}}</span>
              <router-link :to="{ path: '/detail', query: {nc: 101034004,id:item.id } }" target="_blank"><span id="span">{{item.title}}</span></router-link>
            </li>
            <li>
              <img src="img/more.png" />
              <router-link :to="{ path: '/detail', query: { nc: 101034004 } }" target="_blank"><span id="span" style="color: #8a8a8a">查看更多</span></router-link>
            </li>
          </ul>
        </div>

        <div rel="tab-2" style="display: block;padding: 10px;" v-if="active === 1">
          <ul v-if="waterNotice1.length">
            <li v-for="(item,index) in waterNotice1" :key="index">
              <img src="img/nav.jpg" />
              <span id="time">{{item.createtime | Date}}</span>
              <router-link :to="{ path: '/detail', query: { nc: 101034005 ,id:item.id }}" target="_blank" title="标题">
                <span id="span">{{ item.title }}</span>
              </router-link>
            </li>
            <li>
              <img src="img/more.png" />
              <router-link :to="{ path: '/detail', query: { nc: 101034005 } }" target="_blank"><span id="span" style="color: #8a8a8a">查看更多</span></router-link>
            </li>
          </ul>
          <el-empty v-else description="暂无数据" :image-size="150"></el-empty>
        </div>

        <div rel="tab-3" style="display: block;padding: 10px;" v-if="active === 2">
          <ul v-if="waterNews.length">
            <li  v-for="(item,index) in waterNews" :key="index">
              
              <router-link :to="{ path: '/detail', query: { nc: 101034002,id:item.createTime } }" target="_blank" title="标题">
                <div style="display:flex">
                <img src="img/nav.jpg" />
                <span style="flex:1">{{ item.date}}水质信息</span>
                <span >{{item.createTime}}</span>
                </div>
              </router-link>
            </li>

            <li>
              <img src="img/more.png" />
              <router-link :to="{ path: '/detail', query: { nc: 101034002 } }" target="_blank">
                <span id="span" style="color: #8a8a8a">查看更多</span>
              </router-link>
            </li>
          </ul>
          <el-empty v-else description="暂无数据"></el-empty>
        </div>
      </div>
      <div v-if="none" class="hot">
        <div class="h_top">
          <div>优化营商环境</div>
          <router-link :to="{path:'detail',query:{nc:101034001}}" target="_blank">
            <span>MORE +</span>
          </router-link>
        </div>
        <ul style="padding:10px;overflow-y:auto;height:300px;" v-if="optimizeTheBusinessEnv.length">
          <li v-for="(item,index) in optimizeTheBusinessEnv" :key="index">
              <img src="img/nav.jpg" />
              <span id="time">{{item.createTime}}</span>
              <a :href="item.url" target="_blank" :title="item.title">
                <span id="span">{{ item.title }}</span>
              </a>
            </li>
        </ul>
        <el-empty v-else description="暂无数据"></el-empty>
      </div>
      <!-- <div class="hot" v-else>
        <div class="h_top">
          <div>优化营商环境</div>
          <router-link :to="{path:'detail',query:{nc:101034001}}" target="_blank">
            <span>MORE +</span>
          </router-link>
        </div>
        <div class="h_txt">
          <ul>
            <li
              style="height: auto;line-height: 24px;border-bottom: 1px dotted #ccc;padding-bottom: 15px;">
              <router-link :to="{ path: '/detail', query: { nc: 101034001 } }" target="_blank">
                <img src="img/20210622040130083.png" id="hot_img"/>
              </router-link>
              <dl>
                <dt>
                  <router-link :to="{ path: '/detail', query: { nc: 101034001, id: waterExcellent1[0].id } }" target="_blank">{{ waterExcellent1[0].compoundName }}相关工程公示详细信息</router-link>
                </dt>
                <dd></dd>
              </dl>
              <div style="clear: both"></div>
            </li>

            <li v-for="(item,index) in waterExcellent1" :key="index">
              <img src="img/nav.jpg" />
              <div id="time">{{ item.createTime | Date}}</div>
              <router-link :to="{path:'/detail',query:{nc:101034001,id:item.id}}" target="_blank">
                <span id="span">{{ item.compoundName }}工程公示</span>
              </router-link>
              <div style="clear: both"></div>
            </li>
          </ul>
        </div>
      </div> -->

    </div>
    <div class="news" >
      <div class="top">
        <span id="bt">水务新闻</span>
        <!-- <span id="more">
          <router-link :to="{path:'/company_dynamics',query:{nc:101033002}}" target="_blank" >MORE +</router-link>
        </span> -->
      </div>
      <ul style="height: 330px; overflow: auto; border: 1px solid #eee;">
        <li style="line-height:32px;" v-for="(item,index) in news" :key="index">
              <img src="img/nav.jpg" />
              <div id="time">{{ item.createTime}}</div>
              <a :href="item.url" target="_blank">
                <span id="span">{{ item.title }}</span>
              </a>
              <div style="clear: both"></div>
        </li>
      
      </ul>
      
      <!-- <div v-if="none">
        <el-empty description="暂无数据"></el-empty>
      </div>

      <div class="n_big" v-else>
        <div class="n_img">
          <router-link :to="{path:'/detail',query:{nc:101033002,id:SwNews1[0].id}}" target="_blank"><img :src="SwNews1[0].thumb_media_id"/></router-link>
        </div>
        <div class="n_txt">
          <dl>
            <dt>
              <img src="img/news_hot.png" style="margin-right: 8px; float: left"/>
              <router-link :to="{path:'/detail',query:{nc:101033002,id:SwNews1[0].id}}" target="_blank">{{SwNews1[0].title}}</router-link>
            </dt>
            <dd>{{SwNews1[0].digest}}</dd>
          </dl>
          <ul>
            <li v-for="(item,index) in SwNews1" :key="index">
              <img src="img/nav.jpg" />
              <div id="time">{{item.update_time | Date}}</div>
              <router-link :to="{path:'/detail',query:{nc:101033002,id:item.id}}" target="_blank">
                <div id="span">{{item.title}}</div>
              </router-link>
              <div style="clear: both"></div>
            </li>
          </ul>
        </div>
      </div> -->
    </div>
  </div>
</template>
<style>
.tabs{
  height: 358px;
}
.hot{
  height: 358px;
}
.news{
  margin-top: 60px;
}
</style>
<script>
// 引入 pageBanner组件
import pageBanner from '../components/pageBanner'
// 引入http
import http from "../http/index";
export default {
  data() {
    return {
      news:[],
      optimizeTheBusinessEnv:[],
      active: 0,
      banners: [
        {
          href: window.location.href,
          imgURL: 'img/hengdong/banner2.png',
        },
        {
          href: window.location.href,
          imgURL: 'img/hengdong/banner1.png',
        },
        {
          href: window.location.href,
          imgURL: 'img/hengdong/banner3.png',
        },
        {
          href: window.location.href,
          imgURL: 'img/hengdong/banner4.png',
        },
      ],
      // 停水公告数据
      cutWaterlist:[],
      // 水务新闻数据
      SwNews:[],
      // 水质公告
      waterNews:[],
      waterNotice:[],
      waterExcellent:[],
      none:true
    }
  },
  components: {
    // 注册pageBanner组件
    pageBanner,
  },
  methods:{
    //获取衡东水司新闻
    async getNews(){
      this.news = await http.getNews()
    },
    //获取优化营商环境
    async getOptimizeTheBusinessEnv(){
      this.optimizeTheBusinessEnv = await http.getOptimizeTheBusinessEnv();
      console.log('optimizeTheBusinessEnv',this.optimizeTheBusinessEnv)
    },
    // 获取停水公告数据
    async cutWater() {
      try {
        const res = await http.cutWater();
        
        this.cutWaterlist = res.sort(this.SortDate('createtime',false))
        console.log("-------cutWate----------r", res);
        // console.log(this.cutWaterlist,'++++++++');
      } catch (ex) {
        console.log(ex);
      }
    },
    // 获取水务新闻
    async getNewsMsg() {
      try {
        const res = await http.getNewsMsg();
        // console.log("SWnews", res);
        this.SwNews = res.rows.sort(this.SortDate('update_time',false))
        // console.log(this.SwNews)
      } catch (ex) {
        console.log(ex);
      }
    },
    // 获取水质公告
    async getWater() {
      try {
       let waterNews = await http.getWaterQualityReport();
       this.waterNews = waterNews.slice(0,6)
        // console.log("shuizhi", res);
        // this.waterNews = Object.keys(res)
        // res.forEach(r=>{
        //   this.waterNews.push(Object.keys(r))
        // })
        // console.log('=====',this.waterNews)
      } catch (ex) {
        console.log(ex);
      }
    },
    async getNotice(){
      try {
        const res = await http.getNotice();
        // console.log("通知公告", res);
        this.waterNotice = res.sort(this.SortDate("createtime",false))
        // this.waterNews = Object.keys(res)
        // console.log('=====',this.waterNews)
      } catch (ex) {
        console.log(ex);
      }
    },

    //
    async getExcellent(){
      try {
        const res = await http.getExcellent();
        // console.log("工程", res);
        this.waterExcellent = res.sort(this.SortDate("createtime",false))
      } catch (ex) {
        console.log(ex);
      }
    },

    // 按时间进行排序算法
    SortDate(time,type){
      //createtime
      return function (a,b){
        let value1 = a[time]
        let value2 = b[time]
        if(type){
          //升序排序
          return Date.parse(value1) - Date.parse(value2)
        }else{
          //降序
          return Date.parse(value2) - Date.parse(value1)
        }
      }
    }
  },
  mounted() {
    // 执行获取停水数据方法
    this.cutWater(),
    // 执行获取水务新闻数据方法
    this.getNewsMsg(),
    // 执行获取水质公告的方法
    this.getWater()
    this.getNotice()
    this.getExcellent()
    this.getOptimizeTheBusinessEnv()
    this.getNews()
  },
  computed:{
    // 显示停水公告的前五条数据
    cutWaterlist1(){
      return this.cutWaterlist.slice(0,6)
    },
    // 显示水务新闻的 前四条数据
    SwNews1(){
      return this.SwNews.slice(0,4)
    },
  //  显示水质公告前6条数据
    waterNews1(){
      return this.waterNews.slice(0,6)
    },
    waterNotice1(){
      return this.waterNotice.slice(0,6)
    },
    waterExcellent1(){
      return this.waterExcellent.slice(0,3)
    }
  },
  filters:{
    // 返回一个日期 月-日
    Date(val){
      return val.slice(5,10)
    },
  },
}


</script>