import home from "./pages/home"
import introduce from "./pages/introduce"
import business_environment from "./pages/business_environment" //优化营商环境
export default [{
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        component: home
    },
    {
        path: '/introduce',
        component: introduce
    },
    // {
    //     path: '/company_dynamics',
    //     component: company_dynamics
    // },
    {
        path: '/company_dynamics/:active',
        component: () => import('./pages/company_dynamics_hengdong.vue'),
    },
    // {
    //     path: '/announcement',
    //     component: announcement
    // },
    // {
    //     path: '/detail',
    //     name:'detail',
    //     props: true,
    //     component: () => import('./pages/detail.vue'),
    // },
    {
        path: '/detail',
        name:'detail',
        props: true,
        component: () => import('./pages/water_quality_report_hengdong.vue'),
    },
    {
        path: '/announcement',
        component: () => import('./pages/announcement_hengdong.vue'),
    },
    {
        path: '/business_environment',
        component: business_environment
    }

]